import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";
export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

const Page = ({ data }) => {
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Terms and Conditions | Brierton, Jones & Jones"
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-32">
        <div className="absolute left-0 top-0 h-full w-full">
          <StaticImage
            src="../images/News/Hero Desktop.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container relative">
          <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-50"></div>
          <h1 className="mb-0 text-white">Terms and Conditions</h1>
        </div>
      </section>

      <section className="pb-20 pt-16 md:pb-32 md:pt-22">
        <div className="container">
          <h2>
            TERMS AND CONDITIONS FOR ONLINE PAYMENTS BRIERTON, JONES & JONES.
            LLP
          </h2>
          <p>
            These online payment terms and conditions (“Terms”) apply to your
            use of the online payment transaction service (the “Service”) made
            available by Brierton, Jones & Jones, LLP (“BJJ”). These Terms
            constitute a contract between you and BJJ. Please read them
            carefully. These Terms may be altered or added to by BJJ at any
            time, with or without notice. By checking the box where indicated
            each time you use the Service, you agree to be bound by the
            provisions of these Terms.
          </p>
          <ol className="py-1">
            <li className="font-bold">1. Terms</li>
            <ol className="ml-2">
              <li className="">
                1.1. These Terms form part of BJJ’s website terms and
                conditions, which govern your access to and usage of the payment
                platform on the BJJ website. By agreeing to comply with the
                Terms you also agree to comply with BJJ’s website terms and
                conditions of use. If there is a conflict between BJJ’s website
                terms and conditions of use and these Terms, these Terms shall
                prevail.
              </li>
            </ol>
            <li className="font-bold">2. Grant of Rights</li>
            <ol className="ml-2 py-1">
              <li>
                2.1. You may use the Service for the purpose of making payments
                to BJJ via online credit card transaction or ACH, provided that
                you do not:
              </li>
              <ol className="ml-2 py-1">
                <li>
                  2.1.1. In any way damage or disrupt the Service and/or the
                  operation of the Service; and
                </li>
                <li>
                  2.1.2. Use the Service for any unauthorized or unlawful
                  purpose.
                </li>
              </ol>
              <li>
                2.2. Payments may be made using the Service for invoices
                generated by BJJ for legal fees and costs incurred on your
                behalf.
              </li>
              <li>
                2.3. You cannot use the Service for the transfer of money into
                BJJ’s trust account for payment by BJJ to others on your behalf.
                BJJ trust monies should be paid by check or wire transfer.
              </li>
              <li>
                2.4. We reserve the right to suspend, amend or cancel the
                Service at any time either temporarily or permanently.
              </li>
            </ol>
            <li className="font-bold">3. Payment</li>
            <ol className="ml-2 py-1">
              <li>
                3.1. Payments using the Service may be made only using only Visa
                or MasterCard credit cards.
              </li>
              <li>
                3.2. BJJ is not directly involved in the online payment but
                utilizes a third-party service provider.
              </li>
              <li>
                3.3. All payments are to be in United States Dollars (USD).
              </li>
              <li>
                3.4. It is your responsibility to verify that all transaction
                information and other details are correct.
              </li>
              <li>
                3.5. BJJ has no liability for transactions which are incorrect
                as a result of inaccurate data entry in the course of the use of
                the Service or for loss of data or information caused by factors
                beyond our control.
              </li>
              <li>
                3.6. You will receive a confirmation email once the payment has
                been received.
              </li>
              <li>
                3.7. Once a payment has been made it cannot be cancelled. We do
                not accept any responsibility for refusal or reversal of
                payments, which shall be a matter between you and your credit
                card issuer.
              </li>
            </ol>
            <li className="font-bold">4. Fees</li>
            <ol className="ml-2 py-1">
              <li>
                4.1. BJJ is not responsible for merchant fees for payments made
                by credit card.
              </li>
              <li>
                4.2. The party issuing an ACH payment will incur a $3 fee for
                any returned payment and a $25 fee for any ACH payments that are
                stopped or disputed.
              </li>
              <li>
                4.3. International cardholders using the Service may incur their
                own bank’s international service fees, in addition to the
                merchant service fee.
              </li>
            </ol>
            <li className="font-bold">5. Warranties and Disclaimers</li>
            <ol className="ml-2 py-1">
              <li>5.1. You warrant that:</li>
              <ol className="ml-2 py-1">
                <li>5.1.1. You are aged 18 or over;</li>
                <li>
                  5.1.2. You have the appropriate authority to validly accept
                  the Terms and are able to and will meet your obligations in
                  relation to the Terms;
                </li>
                <li>
                  5.1.3. The credit card used in connection with the Service is
                  issued in your name;
                </li>
                <li>
                  5.1.4. You will pay the credit card issuer all charges
                  incurred in the use of the Service; and
                </li>
                <li>
                  5.1.5. The information supplied by you is true and correct.
                </li>
              </ol>
              <li>
                5.2. To the extent permitted by law, BJJ does not accept
                liability for any damage, loss, costs (including legal costs),
                expenses, indirect losses or consequential damage of any kind
                which may be suffered or incurred by you from the use of the
                Service.
              </li>
              <li>
                5.3. BJJ does not warrant or guarantee that the Service and/or
                your use of the Service will be error-free, immediate, virus
                free and/or continuously available or that the information
                provided through the Service will be complete, accurate and/or
                up to date.
              </li>
            </ol>
            <li className="font-bold">6. Indemnity</li>
            <ol className="ml-2 py-1">
              <li>
                6.1. You agree to indemnify BJJ for any loss, cost or expense
                suffered or incurred by BJJ as result of:
              </li>
              <ol className="py-1">
                <li>6.1.1. Your breach of your obligations under the Terms;</li>
                <li>Intentional misuse of the Service;</li>
                <li>
                  6.1.2. Your negligent acts or omissions in using the Service;
                  or
                </li>
                <li>
                  6.1.3. Any claim brought against BJJ by a third party in
                  relation to your use of the Service.
                </li>
              </ol>
            </ol>
            <li className="font-bold">7. Privacy and Use of Information</li>
            <ol className="ml-2 py-1">
              <li>
                7.1. Any personal information supplied by you may be retained by
                BJJ until such time as you request that the information to be
                removed. BJJ does not store credit card information is PCI
                Compliant.
              </li>
              <li>
                7.2. BJJ will use any information you provide and which
                identifies you only for the purpose for which it is supplied to
                BJJ, including contacting you to verify your credit card
                information, and will not use it for any other purpose or supply
                it to any third party except as required by law, authorized by
                you or set out in these Terms.
              </li>
              <li>
                7.3. Payments using the Service are made through a secure
                third-party website. We will use all reasonable endeavors to
                prevent unauthorized access to your personal information.
                However, BJJ will not be liable for any direct or indirect
                damage or loss whatsoever for any interception and/or ‘hacking’
                of any data or other unauthorized access to information provided
                by you for the purposes of using the Service.
              </li>
            </ol>
            <li className="font-bold">8. Governing Law</li>
            <ol className="ml-2 py-1">
              <li>
                8.1. These Terms are governed by the laws of the State of
                California. The San Diego Superior Courts have exclusive
                jurisdiction over any matter in connection with the Services and
                the Terms.
              </li>
            </ol>
          </ol>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
