import React from "react";

import ButtonSolid from "../Button/ButtonSolid";

const CTA = ({ heading, headingLevel, text, paddingTop, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div
      className={`bg-blue md:bg-transparent ${paddingTop && "pt-20 md:pt-0"}`}
    >
      <section className={`bg-gradient mx-4 py-18 md:mx-0 ${className}`}>
        <div className="container">
          <div className="md:flex md:items-start md:justify-between md:space-x-6">
            <div className="mb-8 max-w-[758px] text-center md:mb-0 md:text-left">
              <HeadingTag className="text-snow">
                {heading || "Let Us Represent You"}
              </HeadingTag>
              <p className="font-display text-gray-50 md:mb-0 md:text-xl">
                {text ||
                  "When you need a probate, trust, and estate attorney who will listen, offer solutions, and help you solve your problem, Brierton, Jones & Jones, LLP is here for you."}
              </p>
            </div>

            <div className="grid gap-y-4">
              <ButtonSolid
                href="tel:619-696-7066"
                altStyle={4}
                text="(619) 696-7066"
              />
              <ButtonSolid
                modal="modal-contact"
                altStyle={2}
                text="Send a Message"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CTA;
